import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from "./box"
import Grid from "./grid"
import RoomNavigationItem from "./room-navigation-item"

const RoomNavigation = ({ headingElement }) => {
  const data = useStaticQuery(graphql`
    query RoomNavigationQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "dauerausstellung" }
          extension: { eq: "mdx" }
        }
        sort: { order: ASC, fields: childMdx___frontmatter___order }
      ) {
        edges {
          node {
            childMdx {
              id
              fields {
                slug
              }
              frontmatter {
                title
                order
                cover {
                  childImageSharp {
                    gatsbyImageData(
                      width: 150
                      height: 150
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { edges: rooms } = data.allFile

  return (
    <Box>
      <Grid as="ol" columns={[1, 2]} space="3">
        {rooms.map(({ node: room }, index) => (
          <RoomNavigationItem
            headingElement={headingElement}
            key={`room_${index}`}
            room={room}
          />
        ))}
      </Grid>
    </Box>
  )
}

RoomNavigationItem.propTypes = {
  headingElement: PropTypes.string,
}

RoomNavigationItem.defaultProps = {
  headingElement: "h5",
}

export default RoomNavigation
